<template>
  <div>
    <h3 ref="intro">
      Statement of Bernoulli's Principle
    </h3>
    <p>
      Bernoulli's principle states that for an inviscid, incompressible flow, the sum of static head,
      kinetic head and hydrostatic head remains constant along a streamline.
      Let \(P\) be the static presure, \(v\) be the velocity and \(h\) be the elevation of a point along the streamline.
      Then, according to Bernoulli's principle:
      $$ P + \frac{1}{2} \rho v^2 + \rho g h = constant $$
      Thus, Bernoulli's principle can be used to relate pressure, velocity and elevation
      at any two points along a streamline. For example:
      $$ P_1 + \frac{1}{2} \rho v_{1}^{2} + \rho g z_1 = P_2 + \frac{1}{2} \rho v_{2}^{2} + \rho g z_{2} $$
    </p>
    <p>
      Further, for a constant elevation flow i.e. \(z_1 = z_2\), Bernoulli's equation becomes:
      $$ P_1 + \frac{1}{2} \rho v_{1}^{2} = P_2 + \frac{1}{2} \rho v_{2}^{2} $$
      along a streamline in an inviscid, incompressible flow.
      Thus, an increase in fluid velocity is accompanied by a decrease in fluid pressure, and vice versa.

      Bernoulli's principle has a number of applications ranging from velocity measurement in a pitot tube,
      design of lift in airfoils, to design of hydraulic ducts in chemical plants.
    </p>
    <h3 ref="appl">
      Conditions for Applicabiilty of Bernoulli's Principle
    </h3>
    <p>
      Bernoulli's equation, in the strictest sense, is applicable only when the following flow conditions
      are satisfied:
      <ul style="list-style-type:square;">
        <li>
          Fluid is inviscid &#8213; which means it has zero viscosity and hence the viscous losses are absent.
        </li>
        <li>
          Flow is incompressible &#8213; which means the density of a fluid element along a streamline
          remains constant.
        </li>
      </ul>
    </p>

    <h3 ref="derivations">
      Derivation of Bernoulli's principle
    </h3>
    <p>
      Bernoulli's principle is essentially a statement of conservation of total energy. The real fluids always have viscous losses due to viscosity (resulting in inter-layer frictions).
      Bernoulli's principle is an idealization based on the notion of an inviscid flow. During such a flow, the total energy of the fluid remains constant along a streamline. The total energy of a fluid is comprised of
      the potential energy (due to the action of gravity on the fluid) and the kinetic energy (due to velocity of the fluid elements). Now, consider a fluid element of volume \(\Delta V\) traveling from point '1' to point '2' along a duct
      as shown in the figure below.
      The change in gravitational potential energy of this fluid element is given as:
      $$ \Delta \mathcal U = \rho g \Delta V (h_1 - h_2) $$
      where \(\rho\) is the density of the fluid element, \(h_1\) is the elevation of section '1' and \(h_2\) is the elevation of section '2'.
      The change in kinetic energy of this fluid element is given as:
      $$ \Delta \mathcal K = \frac{1}{2} \rho \Delta V (v_2^{2} - v_1^{2}) $$
      where \(v_1\) and \(v_2\) are fluid velocities at sections '1' and '2', respectively.
      The external work done on the fluid element is given as:
      $$ \mathcal W_{ext} = \delta(P\ \Delta V) = \delta P \Delta V + P \delta \Delta V$$
      Since the flow is incompressible, we have \(\delta V = 0\). Thus, we have:
      $$ \mathcal W_{ext} = (P_2 -  P_1) V $$
      where  \(P_1 \) is the fluid pressure at point '1' of the streamline, and \(P_2\) is the fluid pressure at point '2' of the streamline.
      Now, according to the work-energy theorem, the change in total energy should equal the external work done on the fluid element. Mathematically, it can be expressed as:
      $$\mathcal W_{ext} = \Delta \mathcal K + \Delta \mathcal U$$
      Substituting the various values, we obtain:
      $$ (P_1 - P_2) \Delta V = \frac{1}{2} \rho \Delta V (v_2^{2} - v_1^{2}) + \rho g \Delta V (h_2 - h_1) $$
      Upon rearranging, we obtain:
      $$ P_1 + \frac{1}{2} \rho v_1^{2} + \rho g h_1 = P_2 + \frac{1}{2} \rho v_2^{2} + \rho g h_2 $$
      Then, the general expression for Bernoulli's equation is:
      $$ P +\frac{1}{2} \rho v_1^{2} + \rho gh = constant $$
    </p>
    <h3 ref="continuity">
      Continuity Equation
    </h3> <br>
    <p>
      For incompressible flows, the velocity of a fluid element can be related to the geometry of the duct via continuity equation. The continuity equation states:
      $$ A_1 v_1 = A_2 v_2 $$
      where \(A_1\) and \(A_2\) are the cross-sectional areas at the two sections, and \(v_1\) and \(v_2\) are the respective velocities at those sections. While Bernoulli's equation is a statement of conservation of energy, the continuity equation is a statement of the conservation of mass.
      Bernoulli's equation in conjunction with continuity equation allows us
      to fully determined fluid pressure and velocity at any section along
      the streamline.
    </p>
    <h3 ref="PlayGraph">
      MagicGraph | Fluid Flow in a Vertical Duct
    </h3>
    <h5> To Get Started</h5>
    <p>
      You are given a duct carrying an incompressible fluid.
      The height and the cross-section area of the inlet and outlet sections of the duct can be changed by using the sliders.
      For the velocity and the pressure prescribed at the inlet (section '1'),
      the MagicGraph determines the pressure and the velocity at the outlet (section '2').
    </p>
    <v-responsive>
      <v-layout align-center justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
      <v-layout justify-center>
        <h5> Follow this link <a class="icn" href="https://edliy.com/magic"> <i class="fa fa-external-link-square" /></a> to learn how MagicGraphs help students accelerate their learning curve. </h5>
      </v-layout>
    </v-responsive>
  </div>
</template>

<script>
import Boxes from './Boxes.js'

export default {
  name: 'BernoulliPrinciple',
  data () {
    return {
      v1: 1,
      P1: 1,
      rho: 1,
      v2: undefined,
      P2: undefined,
    }
  },
  created: function () {
    // Store mutations
    let title = 'Bernoulli\'s Principle';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    let newTopics = [
      {title: ' Statement of Bernoulli\'s Principle', img:'/assets/number-1.svg',action: () => this.goto('intro')},
      {title: 'Derivation of Bernoulli\'s Principle', img:'/assets/number-2.svg',action: () => this.goto('derivation')},
      {title: 'Continuity Equation', img:'/assets/number-3.svg',action: () => this.goto('continuity')},
      {title: 'Conditions for Applicability', img:'/assets/number-4.svg',action: () => this.goto('appl')},
      {title: 'MagicGraph: Fluid Flow in a Vertical Duct', img:'/assets/touch.svg',action: () => this.goto('PlayGraph')},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newPhys =true;
    this.$store.commit('navigation/replacePhys', newPhys);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule =true;
    this.$store.commit('navigation/replaceModule', newModule);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();

  },
  updated () {
    // MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
  }
}
</script>
<style lang="scss">
@import 'src/styles/edliy-box.scss';
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';
@import 'src/styles/screen-sizes.scss';
</style>

// Import the edliy_utils
import {
    makeResponsive,
    placeTitle,
    placeImage,
    placeInput,
    placeSlider,
    hoverMe,
    placeRec,
    hiddenPt,
    fixedPt,
    clearInputFields,
    dragMe,
    placeArrow,
    placeGravity,
    placeText,
    placeLine,
    placePoint,
    placeGlider,
    placeRuler,
    placeLeftText,
    placeRightText,
    placeSliderSwitch,
    placeDash
} from '../../../common/edliy_utils-bernoulli';
const Boxes = {
  box1: function () {
  var brd1 = JXG.JSXGraph.initBoard('jxgbox1',{boundingbox: [-1, 15, 15, -1],
  keepaspectratio: true, axis:true, ticks:{visible:false},
  grid:true, showCopyright:false, showNavigation:false,
  pan:{enabled:false}, zoom:{enabled:false}});
  brd1.suspendUpdate();
  brd1.options.board.minimizeReflow = 'none';
  brd1.options.text.highlight =false;
  brd1.options.text.fixed =true;
  brd1.options.text.highlight =false;
  brd1.options.text.cssStyle='fontFamily:Oswald',
  brd1.options.image.highlight =false;
  brd1.options.polygon.highlight =false;
  brd1.options.line.highlight =false;
  brd1.options.point.highlight =false;
  brd1.options.curve.highlight =false;
  brd1.options.slider.highlight =false;
  brd1.options.point.showInfoBox =false;
//brd1.options.layer['image'] =14;
//Title and tetxs
  makeResponsive(brd1);
  placeTitle(brd1, 'Bernoulli\'s Principle', '');
  //var title1 = brd1.create('text', [7, 14, "<b> Bernoulli's Principle <b>"],{fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(28*brd1.canvasWidth/500.)}},);

//Sliders
//var p1 = brd1.create('slider',[[0,12],[2,12],[100000,600000,1000000]], {snapToGrid: false ,face:'square', size:4, strokeWidth: 2, name:'P_1', strokeColor:'black', fillColor: 'grey',  label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
//var v1 = brd1.create('slider',[[0,11],[2,11],[1,3,5]], {snapToGrid: false ,face:'square', size:4, strokeWidth: 2, name:'v_1', strokeColor:'black', fillColor: 'grey',  label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
var p1 = placeSlider(brd1, 1, 12, 100000, 200000, 600000, 2, '&nbsp; P_1', ' Pa');
var v1 = placeSlider(brd1, 1, 11, 0.1, 1, 2, 2, '&nbsp; v_1', ' m/s');
//var r1 = brd1.create('slider',[[0,10],[2,10],[0.1,1,5]], {snapToGrid: false ,face:'square', size:4, strokeWidth: 2, name:'r_1', strokeColor:'black', fillColor: 'grey',  label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
//var r2 = brd1.create('slider',[[0,9],[2,9],[0.1,0.5,5]], {snapToGrid: false ,face:'square', size:4, strokeWidth: 2, name:'r_2', strokeColor:'black', fillColor: 'grey',  label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
var r1=placeSlider(brd1, 1, 9, 0.1, 1, 2, 2, '&nbsp; r_1', ' m');
var r2=placeSlider(brd1, 11, 9, 0.1, 0.5, 2, 2, '&nbsp; r_2', ' m');
//Height
var segh1 = brd1.create('segment', [[0,0], [0, 8]], {visible: false, fixed: true, strokeColor: 'black', strokeWidth: 3});
var segh2 = brd1.create('segment', [[14,0], [14, 13]], {visible: false, fixed: true, strokeColor: 'black', strokeWidth: 3});

brd1.create('axis', [[14, 0],[14, 10]]);
var h1 = brd1.create('glider', [0,5, segh1],{face:'>' , name:'', size:6, strokeColor:'black', withLabel:true, fixed:false, visible:true, label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
var h2 = brd1.create('glider', [0,3, segh2],{face:'<' , name:'', size:6, withLabel:true, strokeColor:'black', fixed:false, visible:true, label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
//brd1.create('arrow', [[13, 0],[13, ()=>h2.Y()]]);
placeArrow(brd1,[13, 0],[13, ()=>h2.Y()], 2, 'grey');
placeArrow(brd1,[1, 0],[1, ()=>h1.Y()], 2, 'grey');
placeDash(brd1, [0, ()=>h1.Y()], [2, ()=>h1.Y()], 2, 'black');
placeDash(brd1, [12, ()=>h2.Y()], [14, ()=>h2.Y()], 2, 'black');
placeRightText(brd1, 13.5, ()=>0.5*h2.Y(), 'h_2');
placeRightText(brd1, 1.5, ()=>0.5*h1.Y(), 'h_1');
//
//Circle 1
var pC1 = brd1.create('point', [4, function(){return h1.Y()}],{face:'o' , name:'A', size:2, withLabel:true, fixed:false, visible:false, label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
var pCup = brd1.create('point', [2, function(){return h1.Y() + r1.Value()}],{face:'o' , name:'A', size:2, withLabel:true, fixed:false, visible:false, label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
var pCdown = brd1.create('point', [2, function(){return h1.Y() - r1.Value()}],{face:'o' , name:'A', size:2, withLabel:true, fixed:false, visible:false, label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});

var pC11 = brd1.create('point', [function(){return pC1.X() - 4}, function(){return pC1.Y()}],{face:'o' , name:'A', size:2, withLabel:true, fixed:false, visible:false, label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});

var c1 = brd1.create('arc', [pC1, pCup, pCdown], {strokeColor: 'blue', strokeWidth: 3});
var c11 = brd1.create('arc', [pC11, pCdown , pCup], {strokeColor: 'blue', strokeWidth: 3});

//Circle 2
var pC1_2 = brd1.create('point', [14, function(){return h2.Y()}],{face:'o' , name:'A', size:2, withLabel:true, fixed:false, visible:false, label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
var pCup_2 = brd1.create('point', [12, function(){return h2.Y() + r2.Value()}],{face:'o' , name:'A', size:2, withLabel:true, fixed:false, visible:false, label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
var pCdown_2 = brd1.create('point', [12, function(){return h2.Y() - r2.Value()}],{face:'o' , name:'A', size:2, withLabel:true, fixed:false, visible:false, label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});

var pC11_2 = brd1.create('point', [function(){return pC1_2.X() - 4}, function(){return pC1_2.Y()}],{face:'o' , name:'A', size:2, withLabel:true, fixed:false, visible:false, label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});

var c2 = brd1.create('arc', [pC1_2, pCup_2, pCdown_2], {strokeColor: 'blue', strokeWidth: 3});
var c22 = brd1.create('arc', [pC11_2, pCdown_2 , pCup_2], {strokeColor: 'blue', strokeWidth: 3});

//Intemediate points

var intUp_1 = brd1.create('point', [6, function(){return h1.Y() + r1.Value()}],{face:'o' , name:'A', size:2, withLabel:true, fixed:false, visible:false, label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
var intDown_1 = brd1.create('point', [6, function(){return h1.Y() - r1.Value()}],{face:'o' , name:'A', size:2, withLabel:true, fixed:false, visible:false, label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});

var intUp_2 = brd1.create('point', [8, function(){return h2.Y() + r2.Value()}],{face:'o' , name:'A', size:2, withLabel:true, fixed:false, visible:false, label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
var intDown_2 = brd1.create('point', [8, function(){return h2.Y() - r2.Value()}],{face:'o' , name:'A', size:2, withLabel:true, fixed:false, visible:false, label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});

//Tube

var seg1 = brd1.create('segment', [pCup,intUp_1 ], {visible: true, fixed: true, strokeColor: 'blue', strokeWidth: 3});
var seg2 = brd1.create('segment', [pCdown,intDown_1 ], {visible: true, fixed: true, strokeColor: 'blue', strokeWidth: 3});

var seg3 = brd1.create('segment', [pCup_2,intUp_2 ], {visible: true, fixed: true, strokeColor: 'blue', strokeWidth: 3});
var seg4 = brd1.create('segment', [pCdown_2,intDown_2 ], {visible: true, fixed: true, strokeColor: 'blue', strokeWidth: 3});

var seg4 = brd1.create('segment', [intUp_1,intUp_2 ], {visible: true, fixed: true, strokeColor: 'blue', strokeWidth: 3});
var seg4 = brd1.create('segment', [intDown_1,intDown_2 ], {visible: true, fixed: true, strokeColor: 'blue', strokeWidth: 3});

//Velocity 2
var density = 1000;

var v2 = function(){return Math.pow(r1.Value(), 2)*v1.Value()/Math.pow(r2.Value(), 2)};

var p2 = function(){return p1.Value()  + 0.5*density* ( Math.pow(v1.Value(), 2) - Math.pow(v2(), 2) ) + 9.81*density*(h1.Y() -h2.Y())  };

brd1.create('text', [9 , 12,  function(){return 'v_2 = ' + v2().toFixed(2)+' m/s';}],{visible: true, fixed: true, anchorX: 'left', anchorY: 'middle', fontSize:function(){return Math.round(12*brd1.canvasWidth/500.)}},);
brd1.create('text', [9 , 11,  function(){return 'P_2 = ' + p2().toFixed(2)+' Pa';}],{visible: true, fixed: true, anchorX: 'left', anchorY: 'middle', fontSize:function(){return Math.round(12*brd1.canvasWidth/500.)}},);
//placeLeftText(brd1, 10, 12, function(){return 'P_2 = ' + p2().toFixed(2);});
//placeLeftText(brd1, 10, 11, function(){return 'v_2 = ' + v2().toFixed(2);});
//Water segments
var pWat1_1 = brd1.create('point', [2, function(){return h1.Y() + r1.Value() - 1*2*r1.Value()/4}],{face:'o' , name:'', size:1, withLabel:true, fixed:false, visible:false, label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
var pWat2_1 = brd1.create('point', [2, function(){return h1.Y() + r1.Value() - 2*2*r1.Value()/4}],{face:'o' , name:'', size:1, withLabel:true, fixed:false, visible:false, label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
var pWat3_1 = brd1.create('point', [2, function(){return h1.Y() + r1.Value() - 3*2*r1.Value()/4}],{face:'o' , name:'', size:1, withLabel:true, fixed:false, visible:false, label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});

var pWat1_1int = brd1.create('point', [6, function(){return h1.Y() + r1.Value() - 1*2*r1.Value()/4}],{face:'o' , name:'', size:1, withLabel:true, fixed:false, visible:false, label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
var pWat2_1int = brd1.create('point', [6, function(){return h1.Y() + r1.Value() - 2*2*r1.Value()/4}],{face:'o' , name:'', size:1, withLabel:true, fixed:false, visible:false, label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
var pWat3_1int = brd1.create('point', [6, function(){return h1.Y() + r1.Value() - 3*2*r1.Value()/4}],{face:'o' , name:'', size:1, withLabel:true, fixed:false, visible:false, label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});

var pWat1_2 = brd1.create('point', [12, function(){return h2.Y() + r2.Value() - 1*2*r2.Value()/4}],{face:'o' , name:'', size:1, withLabel:true, fixed:false, visible:false, label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
var pWat2_2 = brd1.create('point', [12, function(){return h2.Y() + r2.Value() - 2*2*r2.Value()/4}],{face:'o' , name:'', size:1, withLabel:true, fixed:false, visible:false, label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
var pWat3_2 = brd1.create('point', [12, function(){return h2.Y() + r2.Value() - 3*2*r2.Value()/4}],{face:'o' , name:'', size:1, withLabel:true, fixed:false, visible:false, label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});

var pWat1_2int = brd1.create('point', [8, function(){return h2.Y() + r2.Value() - 1*2*r2.Value()/4}],{face:'o' , name:'', size:1, withLabel:true, fixed:false, visible:false, label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
var pWat2_2int = brd1.create('point', [8, function(){return h2.Y() + r2.Value() - 2*2*r2.Value()/4}],{face:'o' , name:'', size:1, withLabel:true, fixed:false, visible:false, label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
var pWat3_2int = brd1.create('point', [8, function(){return h2.Y() + r2.Value() - 3*2*r2.Value()/4}],{face:'o' , name:'', size:1, withLabel:true, fixed:false, visible:false, label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});

var segWat1_1 = brd1.create('segment', [pWat1_1, pWat1_1int], {visible: true, fixed: true, strokeColor: 'blue', strokeWidth: 1});
var segWat2_1 = brd1.create('segment', [pWat2_1, pWat2_1int], {visible: true, fixed: true, strokeColor: 'blue', strokeWidth: 1});
var segWat3_1 = brd1.create('segment', [pWat3_1, pWat3_1int], {visible: true, fixed: true, strokeColor: 'blue', strokeWidth: 1});

var segWat1_int = brd1.create('segment', [pWat1_1int, pWat1_2int], {visible: true, fixed: true, strokeColor: 'blue', strokeWidth: 1});
var segWat2_int = brd1.create('segment', [pWat2_1int, pWat2_2int], {visible: true, fixed: true, strokeColor: 'blue', strokeWidth: 1});
var segWat3_int = brd1.create('segment', [pWat3_1int, pWat3_2int], {visible: true, fixed: true, strokeColor: 'blue', strokeWidth: 1});

var segWat1_2 = brd1.create('segment', [pWat1_2int,pWat1_2], {visible: true, fixed: true, strokeColor: 'blue', strokeWidth: 1});
var segWat2_2 = brd1.create('segment', [pWat2_2int,pWat2_2], {visible: true, fixed: true, strokeColor: 'blue', strokeWidth: 1});
var segWat3_2 = brd1.create('segment', [pWat3_2, pWat3_2int], {visible: true, fixed: true, strokeColor: 'blue', strokeWidth: 1});


var current1 =brd1.create('glider', [2, 0, segWat2_int], {strokeColor: 'black' , fillColor: 'black' , face: '>', size:0, name:'', visible: false, label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
var current2 =brd1.create('glider', [2, 0, segWat2_1], {strokeColor: 'black' , fillColor: 'black' , face: '>', size:0, name:'', visible: false, label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
var current3 =brd1.create('glider', [2, 0, segWat2_2], {strokeColor: 'black' , fillColor: 'black' , face: '>', size:0, name:'', visible: false, label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});

brd1.create('image', ['/assets/rightArrow.svg', [()=>current1.X()-0.15, ()=>current1.Y()-0.15], [0.3, 0.3]]);
brd1.create('image', ['/assets/rightArrow.svg', [()=>current2.X()-0.15, ()=>current2.Y()-0.15], [0.3, 0.3]]);
brd1.create('image', ['/assets/rightArrow.svg', [()=>current3.X()-0.15, ()=>current3.Y()-0.15], [0.3, 0.3]]);
current1.startAnimation(1, 30, 30);
current2.startAnimation(1, 30, 30);
current3.startAnimation(1, 30, 30);

//Water drops

//Function graph
var graph1 = brd1.create('functiongraph',
                     [function(x){

                      if(x>=2){ return h1.Y() + r1.Value() - 1*2*r1.Value()/4}
                      else if(x> 2 && x< 8){ return 1}
                      else if(x>= 8 && x<= 12){return h2.Y() + r2.Value() - 1*2*r2.Value()/4}



                     }, -10, -5] , {visible: true, strokeColor: 'red'}
);


var water1 = brd1.create('glider', [2, 6, graph1],{color: 'blue', face:'o' , name:'', size:2, withLabel:true, fixed:false, visible:true, label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
  brd1.unsuspendUpdate();
    },
}
export default Boxes;
